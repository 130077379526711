<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            กิจกรรม
          </div>
        </div>
      </div>

      <div class="flex flex-wrap mb-6">
        <div class="lg:w-1/2">
          <label class="mb-2">วัน/เดือน/ปี</label>
          <div class="mb-1"></div>

          <v-date-picker
            v-model="date"
            locale="th"
            style="font-family: KanitFont"
          >
            <template class="relative flex justify-items-start justify-start" v-slot="{ inputValue, inputEvents }">
              <span
                class="
                  absolute
                  flex
                  items-center
                  justify-items-center 
                  justify-center
                  mt-2
                "
              >
                <font-awesome-icon
                  class="fa-2x pl-4"
                  icon="calendar-alt"
                  color="#573C81"
                />
              </span>
              <input
                style="padding-left: 40px;"
                placeholder="กรุณาเลือกวัน/เดือน/ปี"
                id="date" 
                :value="inputValue" 
                v-on="inputEvents"
              />
            </template>
            <!-- <template v-slot="{ inputValue, inputEvents }">
              <input id="date" :value="inputValue" v-on="inputEvents" />
            </template> -->
          </v-date-picker>
        </div>
      </div>

      <div class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
        <!--Card 1-->
        <router-link
          to="/activity/1"
          class="rounded overflow-hidden"
          v-for="(item, index) in 9"
          :key="index"
          style="width: 100%; height: 400px; border-width: 1px"
        >
          <img class="object-cover w-full h-1/2" src="@/assets/mog/cover.jpg" />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2" style="color: #fa4238">
              11 พฤษภาคม 2564
            </div>
            <p class="text-sm" style="color: #242424">
              สดช. ร่วมกับ ทส. ประชุมหารือโครงการศูนย์ ข้อมูลสิ่งแวดล้อมแห่งชาติ
              (National Environmental Open Data)
            </p>

            <div class="text-sm mt-4 mb-4" style="color: #9c6de8">
              <a href="#">
                อ่านต่อ
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  color="#9C6DE8"
                />
              </a>
            </div>
          </div>
        </router-link>
      </div>

      <ul class="flex items-center justify-center justify-items-center mb-12">
        <li
          class="mx-1 px-3 py-2 rounded-tl-full rounded-bl-full"
          id="backgroundId0"
          @click="changeColorBackground(0)"
          style="color: #573c81; background-color: rgba(199, 179, 232, 0.3)"
        >
          <div class="flex items-center font-bold">
            <span class="mx-1">1</span>
          </div>
        </li>
        <li
          class="mx-1 px-3 py-2 rounded-lg"
          id="backgroundId1"
          @click="changeColorBackground(1)"
          style="color: #573c81; background-color: rgba(199, 179, 232, 0.3)"
        >
          <div class="font-bold">2</div>
        </li>
        <li
          class="mx-1 px-3 py-2 rounded-lg"
          id="backgroundId1"
          @click="changeColorBackground(2)"
          style="color: #573c81; background-color: rgba(199, 179, 232, 0.3)"
        >
          <div class="font-bold">3</div>
        </li>
        <li
          class="mx-1 px-3 py-2 rounded-lg"
          id="backgroundId1"
          @click="changeColorBackground(3)"
          style="color: #573c81; background-color: rgba(199, 179, 232, 0.3)"
        >
          <div class="font-bold">4</div>
        </li>
        <li
          class="mx-1 px-3 py-2 rounded-tr-full rounded-br-full"
          id="backgroundId2"
          @click="changeColorBackground(4)"
          style="color: #573c81; background-color: rgba(199, 179, 232, 0.3)"
        >
          <div class="flex items-center font-bold">
            <span class="mx-1">5</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  mounted() {
    this.changeColorBackground(null);
  },
  methods: {
    changeColorBackground(id) {
      console.log(id);
      let colorId1 = document.getElementById("backgroundId0");
      if (id === null) {
        colorId1.style.backgroundColor = "#C7B3E8";
      }
    },
  },
  setup() {
    const date = ref(null);
    return { date };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

input {
  background: rgba(199, 179, 232, 0.3);
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

.mat-input-element {
  padding-left: 100px;
}
</style>